import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private readonly snackBar: MatSnackBar) {}

  info(message: string) {
    this.openSnackBar(message, 'info-snackbar');
  }

  success(message: string) {
    this.openSnackBar(message, 'success-snackbar');
  }

  error(message: string) {
    this.openSnackBar(message, 'error-snackbar');
  }

  warning(message: string) {
    this.openSnackBar(message, 'warning-snackbar');
  }

  openSnackBar(message: string, className = '', duration = 3000) {
    this.snackBar.open(message, 'x', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: duration,
      politeness: 'assertive',
      panelClass: [className],
    });
  }
}
