import { APIEndpoint } from './api-endpoint';

export const AUTH_HEADER = 'Authorization';
export const TOKEN_KEY = 'auth-token';
export const REFRESHTOKEN_KEY = 'auth-refreshtoken';
export const USER_KEY =
  '8082ad40b3f9ff1bd0ca1f4d6de342d452b2d202178ba4098dbe2dae64171b69';
export const PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
export const NID_REGEX = '^([0-9]{10}|[0-9]{13}|[0-9]{17})$';
export const EMAIL_REGEX = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$';
export const MOBILE_NO_REGEX = '^(0|[0+]880)1[3-9][0-9]{8}$';
export const MOBILE_NUM_REGEX = '^01[3-9][0-9]{8}$';
export const AIN_NO_REGEX = '^[0-9]{8,12}$';
export const LICENCE_NO_REGEX = '^[0-9]{4}/[0-9]{2}$';
export const BIN_REGEX = '^[0-9]{9}-[0-9]{4}$';
export const NAME_REGEX = '^[A-Za-z]+(?: [A-Za-z]+)*$';

export const ROLE = {
  INITIATOR: 'initiator',
  PRIMARY_APP: 'primary_app',
  FINAL_APP: 'final_app',
  APPROVER: 'approver',
  OPM: 'opm',
  OFFICE_ADMIN: 'office_admin',
  SUPER_ADMIN: 'super_admin',
};

export const SEARCH_FIELD_EVENTS = ['keyup', 'paste'];

export const AVOID_LOADER_LIST = [
  APIEndpoint.GET_USER_INFORMATION,
  APIEndpoint.GET_OFFICE_LIST,
  APIEndpoint.CHECK_AIN_AVAILABILITY,
  APIEndpoint.CHECK_LICENSE_AVAILABILITY,
  APIEndpoint.UPLOAD_FILE,
  APIEndpoint.DELETE_FILE,
  APIEndpoint.DOWNLOAD_FILE,
  APIEndpoint.VALIDATE_BIN,
  APIEndpoint.CHECK_USER_ID_AVAILABILITY,
  APIEndpoint.CHECK_CASE_AIN_VALIDITY,
  APIEndpoint.GET_REFERENCE_AIN_DETAILS,
  APIEndpoint.COUNT_AGENT_AIN_BY_BIN,
  APIEndpoint.CHECK_BIN_VALIDITY,
  APIEndpoint.CHECK_AIN_VALIDITY,
];

export const PUBLIC_API_LIST = [APIEndpoint.SIGNIN];
