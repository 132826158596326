import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@shared/loader/service/loader.service';
import { AVOID_LOADER_LIST } from 'app/constants/constant';
import { Observable, delay, finalize, of, switchMap } from 'rxjs';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (this.skipLoader(AVOID_LOADER_LIST, request.url)) {
      return next.handle(request);
    }

    this.loaderService.show();

    return of(null).pipe(
      delay(500), // Introduce a 0.5-second delay
      switchMap(() => next.handle(request)),
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }

  //function to check if the requested url is present in the skip list.
  private skipLoader(skipList: string[], requestUrl: string){
    for (var i = 0; i < skipList.length; i++) {
      if (requestUrl.indexOf(skipList[i]) !== -1) {
        return true;
      }
    }
    return false;
  }
}

export const loaderInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoaderInterceptor,
  multi: true,
};
