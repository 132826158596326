import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { HttpRequestService } from '@core/http/http-request.service';
import { APIEndpoint } from 'app/constants/api-endpoint';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  
  public currentUrl = new BehaviorSubject<string>('');

  constructor(private router: Router, private _http: HttpRequestService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }



  getUserProfile() {
    return this._http.get(APIEndpoint.GET_USER_INFORMATION);
  }
}
