import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  private _header: HttpHeaders = new HttpHeaders({ responseType: 'JSON' });

  constructor(private _http: HttpClient) {}

  public get(apiEndPoint: string, header?: object): Observable<Object> {
    header = header ?? this._header;
    return this._http.get(apiEndPoint, header);
  }

  public post(
    apiEndPoint: string,
    body: object,
    header?: object
  ): Observable<Object> {
    header = header ?? this._header;
    return this._http.post(apiEndPoint, body, header);
  }

  public put(
    apiEndPoint: string,
    body?: object,
    header?: object
  ): Observable<Object> {
    header = header ?? this._header;
    return this._http.put(apiEndPoint, body, header);
  }

  public delete(
    apiEndPoint: string,
    header?: object,
    requestBody?: object
  ): Observable<Object> {
    const options = {
      headers:
        header === undefined
          ? this._header
          : new HttpHeaders(header as { [key: string]: string | string[] }),
      body: requestBody,
    };
    return this._http.request('delete', apiEndPoint, options);
  }
}
