import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@core/services/logger.service';
import { environment } from '@env';
import { MessageService } from '@shared/message/message.service';
import { catchError, Observable, retry, timer } from 'rxjs';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private _log: LoggerService, private messageService: MessageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry({ count: 1, delay: this.shouldRetry }),
      catchError((error) => {
        if(![401 ,404].includes(error.status)){
          this.messageService.error("We are unable to process this request, please try again after some time.")
        }
        return this.errorHandler(error)
      })
    );
  }

  /**
   * Customize the default error handler here if needed
   * @param response
   * @returns handler
   */
  private errorHandler(
    response: HttpResponseWithMessage
  ): Observable<HttpResponseWithMessage> {
    const errorMessage = `url: ${response.url} status: ${response.status} statusText: ${response.statusText} body: ${response.body}`;
    
    if(!environment.production){
      this._log.error('API error', errorMessage);
    }

    throw response;
  }

  shouldRetry(error: HttpErrorResponse) {
    if (error.status >= 500) {
      return timer(1000);
    }
    throw error;
  }
}

export class HttpResponseWithMessage extends HttpResponse<any> {
  public message?: string;
}

export const httpErrorHandlerInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorHandlerInterceptor,
  multi: true,
};
